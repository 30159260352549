import React, { useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import { renderToReact, renderAsset } from '../rich-text';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import Layout from '../components/layout';
import SEO from '../components/seo';

import styles from './gallery.module.css';

export default ({ data }) => {
  const entries = data.gallery.entries || [];
  const numEntries = entries.length;
  const [activeIndex, setActiveIndex] = useState(0);
  const goToPrevious = useCallback(() => {
    setActiveIndex((activeIndex - 1 + numEntries) % numEntries);
  }, [numEntries, activeIndex]);
  const goToNext = useCallback(() => {
    setActiveIndex((activeIndex + 1) % numEntries);
  }, [numEntries, activeIndex]);

  return (
    <Layout flexbox={true}>
      <SEO title={data.gallery.name} />
      <div className={styles.container}>
        <button
          className={classNames(styles.arrow, styles.left)}
          onClick={goToPrevious}
        >
          <FaChevronLeft />
        </button>
        <div className={styles.main}>
          {entries.map(({ id, asset, caption }, index) => (
            <div
              key={id}
              className={classNames({
                [styles.section]: true,
                [styles.hidden]: index !== activeIndex,
              })}
            >
              {renderAsset(asset, { className: styles.asset })}
              {renderToReact(caption)}
            </div>
          ))}
        </div>
        <button
          className={classNames(styles.arrow, styles.right)}
          onClick={goToNext}
        >
          <FaChevronRight />
        </button>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    gallery: contentfulGallery(id: { eq: $id }) {
      name
      entries {
        id
        asset {
          file {
            url
            contentType
          }
          fluid(maxWidth: 2000, quality: 100) {
            ...GatsbyContentfulFluid_tracedSVG
            aspectRatio
          }
        }
        caption {
          json
          embeds {
            internal {
              type
            }
            ... on ContentfulAsset {
              fields {
                normalizedContentfulId
              }
              file {
                url
                contentType
              }
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyContentfulFluid_tracedSVG
                aspectRatio
              }
            }
            ... on ContentfulLayout {
              fields {
                normalizedContentfulId
              }
              body {
                json
              }
              styles {
                fields {
                  array {
                    key
                    value
                  }
                }
              }
            }
            ... on ContentfulGalleryWithCaptionsWidget {
              fields {
                normalizedContentfulId
              }
              entries {
                id
                asset {
                  file {
                    url
                    contentType
                  }
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyContentfulFluid_tracedSVG
                    aspectRatio
                  }
                }
                caption {
                  json
                }
              }
            }
            ... on ContentfulGalleryWidget {
              fields {
                normalizedContentfulId
              }
              entries {
                id
                file {
                  url
                  contentType
                }
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyContentfulFluid_tracedSVG
                  aspectRatio
                }
              }
            }
            ... on ContentfulSideBySideWidget {
              fields {
                normalizedContentfulId
              }
              entries {
                id
                file {
                  url
                  contentType
                }
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyContentfulFluid_tracedSVG
                  aspectRatio
                }
              }
            }
          }
        }
      }
    }
  }
`;
